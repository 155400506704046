import React from "react";

import { Row } from "react-bootstrap";

import CustomText from "../../components/CustomText";
import Colors from "../../constants/Colors";

import { IoConstruct } from "react-icons/io5";

function UnderConstruction() {
  console.log("Render UnderConstruction");
  return (
    <Row className="mt-5 mb-5 d-flex text-center align-items-center justify-content-center">
      <IoConstruct style={{ height: 150, width: 150 }} color={Colors.primary} />
      <CustomText color={Colors.primary} size={32}>
        Maybe someday :)
      </CustomText>
    </Row>
  );
}

export default UnderConstruction;
