import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Row } from "react-bootstrap";

import CustomText from "../../components/CustomText";
import Colors from "../../constants/Colors";

function PrivacyPolicyEn() {
  console.log("Render PrivacyPolicyEn");

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Row className="ms-3 me-3 mt-4 mb-4">
      <Row className="text-center">
        <CustomText color={Colors.balck} size={24} bold>
          PRIVACY POLICY
        </CustomText>
        <CustomText color={Colors.balck} size={24} bold>
          INFORMATION FORM
        </CustomText>
      </Row>
      <Row className="mt-4">
        <CustomText color={Colors.balck} size={20} bold>
          Personal data controller's details
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          The personal data controller is{" "}
          <b>CRAFTISE.DEV LIMITED LIABILITY COMPANY</b>, conducting a business
          registered in the Central Register and Information on Business at{" "}
          <b>WIZJONERÓW 7/252, 31-356 KRAKÓW, POLAND</b>, having Tax
          Identification Number (NIP): <b>6772503684</b> and National Economy
          Entities Register number (REGON): <b>527257478</b>, who is the owner
          of the service improving preventive health care <b>DIAGME.ONLINE</b>.
        </CustomText>
      </Row>
      <Row className="mt-4">
        <CustomText color={Colors.balck} size={20} bold>
          Personal data controller's contact details
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          The data controller can be contacted via email at{" "}
          <b>contact@craftise.dev</b> or in writing at{" "}
          <b>WIZJONERÓW 7/252, 31-356 KRAKÓW, POLAND</b>.
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          The data controller can be contacted for all matters regarding the
          processing of personal data or the exercise of rights related to data
          processing.
        </CustomText>
      </Row>
      <Row className="mt-4">
        <CustomText color={Colors.balck} size={20} bold>
          Data source - how are the data obtained?
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          As a rule, personal data are provided by you on a voluntary basis
          directly at the time of registering on the service, or they are
          reported in the course of its use.
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          If you voluntarily give your consent and connect your account to a
          service managing data from wearable sensors (e.g. Garmin, Samsung),
          the data are automatically obtained from these services.
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          If you voluntarily give your consent, the data are retrieved
          automatically from entities performing medical tests (e.g. complete
          blood count, lipid profile).
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          It needs to be emphasised that personal data are provided on a
          voluntary basis, but failing to provide these data or restricting
          their scope may make the provision of services impossible or
          incomplete.
        </CustomText>
      </Row>
      <Row className="mt-4">
        <CustomText color={Colors.balck} size={20} bold>
          Scope of personal data processing
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          For the purposes of suggesting medical preventive health care
          activities, such as carrying out medical tests or consulting a doctor,
          the data including an email address, sex, date of birth and any
          medical parameters provided are processed. The above data are also
          used for identity verification in the course of registration on the
          service.
        </CustomText>
      </Row>
      <Row className="mt-4">
        <CustomText color={Colors.balck} size={20} bold>
          Categories of personal data subject to processing
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • Email address
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • Name and surname
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • Sex
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • Date of birth
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • Information concerning health status, including the user's chronic
          diseases and chronic diseases found among their family, as well as
          information about the status of pregnancy
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • Medical test results provided directly by the user or retrieved
          automatically from the entity performing the tests
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • Information on the user's lifestyle provided directly by the user or
          retrieved automatically from an entity collecting such data on the
          user’s behalf
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          If you have given your consent to marketing communication, your data
          in the form of an email address is used.
        </CustomText>
      </Row>
      <Row className="mt-4">
        <CustomText color={Colors.balck} size={20} bold>
          Purposes of data processing and the legal basis for the processing
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • The processing of your personal data is necessary for presenting
          proposals for preventive health care activities, such as performing
          medical tests, consulting a doctor or taking dietary supplements.
          Legal basis: Article 6(1)(a) and Article 6(1)(b) of the GDPR
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • Your data may also be processed for the purposes of keeping
          accounting books and tax accounting. Legal basis: Article 6(1)(c) of
          the GDPR in connection with the provisions of the Polish Act of 29
          September 1994 on Accounting, the Polish Act of 11 March 2004 on Tax
          on Goods and Services and the Polish Act of 26 July 1991 on Personal
          Income Tax.
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • The data may also be processed for the purposes of the defence of
          rights and exercise of claims by the data controller in connection
          with the data controller's activities. Legal basis: Article 6(1)(b)
          and (f) of the GDPR.
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • If you have given your consent to marketing communication, your data
          may be used for marketing purposes in relation to the products and
          services offered by the controller. The legal basis is your consent in
          accordance with Article 6(1)(a) of the GDPR.
        </CustomText>
      </Row>
      <Row className="mt-4">
        <CustomText color={Colors.balck} size={20} bold>
          Data storage period
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          Personal data may be erased at any time directly by the user, which is
          tantamount to erasing the account on the service and ceasing to use
          the service.
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          Data related to the User's health status may be erased at any time,
          but their erasure results in the limitation of the scope of services
          provided .
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          The data collected are erased without undue delay if the User decides
          to no longer use the service or if the User does not use the service
          for a period exceeding 36 months.
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          If you have given your consent to communication for marketing
          purposes, the data will be processed until you withdraw your consent
          to the processing of personal data for these purposes.
        </CustomText>
      </Row>
      <Row className="mt-4">
        <CustomText color={Colors.balck} size={20} bold>
          Data recipients
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          Your data may be transferred to entities processing personal data at
          the controller’s request, including but not limited to IT service
          providers and processors.
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          In addition, if you have given your consent to communication for
          marketing purposes, your data may be transferred to entities
          processing personal data at the controller's request, including but
          not limited to IT service providers or marketing agencies, as well as
          processors.
        </CustomText>
      </Row>
      <Row className="mt-4">
        <CustomText color={Colors.balck} size={20} bold>
          Data transfer outside the EEA
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          Your personal data may be transferred to recipients located in
          countries outside the European Economic Area. In such a case, the
          transfer of data will be based on a relevant contract between the data
          controller and the recipient, which will contain the standard
          data-protection clauses adopted by the European Commission.
        </CustomText>
      </Row>
      <Row className="mt-4">
        <CustomText color={Colors.balck} size={20} bold>
          Data subject's rights
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          You have the right to:
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • <u>Access to your personal data</u> - to obtain from the controller
          confirmation as to whether or not your personal data are being
          processed, and, where that is the case, access the personal data and
          be provided with information to the extent set out in Article 15 of
          the GDPR.
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • <u>Rectification of your personal data </u> - to obtain from the
          controller without undue delay the rectification of inaccurate
          personal data and to have incomplete personal data completed.
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • <u>Erasure of your personal data </u> - to obtain from the
          controller the erasure of personal data without undue delay if one of
          the conditions specified in Article 17 of the GDPR is met, for
          example, when the personal data are no longer necessary in relation to
          the purposes for which they were collected.
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • <u>Restriction </u> of processing of your personal data in the cases
          set out in Article 18 of the GDPR, for example, when contesting the
          accuracy of the personal data.
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • <u>Data portability </u> - to receive your personal data in a
          structured, commonly used and machine-readable format if your data are
          processed based on consent and if the processing is carried out by
          automated means. You may transmit those data to another data
          controller or have your personal data transmitted by the controller
          directly from one controller to another, where technically feasible.
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • <u>Object</u> to the processing of personal data in the cases
          specified in Article 21 of the GDPR.
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          You also have the right to lodge a complaint with the supervisory
          authority in charge of personal data protection.
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          In order to exercise the above rights, please contact the data
          controller. The contact details are provided above.
        </CustomText>
      </Row>
      <Row className="mt-4">
        <CustomText color={Colors.balck} size={20} bold>
          Information about providing personal data on a voluntary basis
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          Providing personal data is a necessary condition for the proper
          functioning of the service, and failing to consent to their processing
          is tantamount to deciding to no longer use the service.
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          Providing the data is also necessary for a bill or invoice to be
          issued.
        </CustomText>
      </Row>
      <Row className="mt-4">
        <CustomText color={Colors.balck} size={20} bold>
          Information about automated decision-making
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          Your personal data will be analysed automatically for the purpose of
          presenting proposals for preventive health care activities. Any
          further operations resulting from the proposals presented will be
          carried out only with the user's consent.
        </CustomText>
      </Row>
    </Row>
  );
}

export default PrivacyPolicyEn;
