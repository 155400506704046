const Colors = {
  primary: "#00C3A5", // green
  secondary: "#007D6A", // dark green
  tertiary: "#EE2C63", // red
  quaternary: "#000000", // black
  backgroundPrimary: "#FFFFFF", // white
  backgroundSecondary: "#506C68", // gray dark green
  backgroundTertiary: "#E7F0EF", // gray ligh green
  backgroundQuaternary: "#E0E0E0", // grey
};

export default Colors;
