import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Row } from "react-bootstrap";

import CustomText from "../../components/CustomText";
import Colors from "../../constants/Colors";

function PrivacyPolicyPl() {
  console.log("Render PrivacyPolicyPl");

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Row className="ms-3 me-3 mt-4 mb-4">
      <Row className="text-center">
        <CustomText color={Colors.balck} size={24} bold>
          POLITYKA PRYWATNOŚCI
        </CustomText>
        <CustomText color={Colors.balck} size={24} bold>
          FORMULARZ INFORMACYJNY
        </CustomText>
      </Row>
      <Row className="mt-4">
        <CustomText color={Colors.balck} size={20} bold>
          Dane administratora danych osobowych
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          Administratorem danych osobowych jest{" "}
          <b>CRAFTISE.DEV SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚĆIĄ</b>,
          prowadząca działalność gospodarczą zarejestrowaną w Centralnej
          Ewidencji i Informacji o Działalności Gospodarczej w{" "}
          <b>WIZJONERÓW 7/252, 31-356 KRAKÓW, POLSKA</b>, posiadający Numer
          Identyfikacji Podatkowej (NIP): <b>6772503684</b> oraz numer Rejestru
          Gospodarki Narodowej (REGON): <b>527257478</b>, właściciela serwisu
          usprawniającego profilaktykę zdrowotną <b>DIAGME.ONLINE</b>.
        </CustomText>
      </Row>
      <Row className="mt-4">
        <CustomText color={Colors.balck} size={20} bold>
          Dane kontaktowe administratora danych osobowych
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          Z administratorem danych można się skontaktować poprzez adres email:
          <b>contact@craftise.dev</b> lub pisemnie na adres:{" "}
          <b>WIZJONERÓW 7/252, 31-356 KRAKÓW, POLSKA</b>.
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          Z administratorem danych można się kontaktować we wszystkich sprawach
          dotyczących przetwarzania danych osobowych lub korzystania z praw
          związanych z przetwarzaniem danych.
        </CustomText>
      </Row>
      <Row className="mt-4">
        <CustomText color={Colors.balck} size={20} bold>
          Źródło danych - skąd pozyskiwane są dane?
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          Co do zasady dane osobowe są przez Pana/Panią podawane dobrowolnie
          bezpośrednio w momencie rejestracji w serwisie lub raportowane w
          trakcie korzystania z niego.
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          W przypadku dobrowolnego wyrażenia zgody i połączenia konta z serwisem
          zarządzającym danymi z sensorów ubieralnych (np.: Garmin, Samsung)
          dane są autmatycznie pozyskiwane z tych serwisów.
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          W przypadku dobrowolnego wyrażenia zgody dane są autmatycznie
          pobierane od podmiotów wykonujących badania medyczne (np.: mofrologia,
          lipidogram).
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          Podkreślenia wymaga, że dane osobowe przekazywane są dobrowolnie, ale
          brak podania tych danych lub ograniczenie ich zakresu może wpłynąć na
          niemożliwość świadczenia usług lub świadczenie ich w niepełnym
          zakresie.
        </CustomText>
      </Row>
      <Row className="mt-4">
        <CustomText color={Colors.balck} size={20} bold>
          Zakres przetwarzania danych osobowych
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          Na potrzeby sugerowania działań profilaktyki medycznej takich jak
          realizacja badań medycznych, czy konsultacja z lekarzem przetwarzane
          są dane obejmujące adres poczty elektronicznej, płeć, datę urodzenia i
          wszelkie dostarczone prametry medyczne. Powyższe dane są
          wykorzystywane także w celu weryfikacji tożsamości w trakcie
          rejestracji w serwisie.
        </CustomText>
      </Row>
      <Row className="mt-4">
        <CustomText color={Colors.balck} size={20} bold>
          Kategorie danych osobowych podlegających przetworzeniu
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • Adres poczty elektronicznej
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • Imię i nazwisko
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • Płeć
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • Data urodzenia
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • Informacje dotyczących stanu zdrowia uwzględniając choroby
          przewlekłe użytkownika i choroby przewlekłe odnotowane w rodzinie, jak
          również informacje o stanie ciąży
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • Wyniki badań medycznych dostarczone bezpośrednio przez użytkownika
          lub pobrane automatycznie od podmiotu realizującego badania
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • Informacje dotyczące stylu życia użytkownika dostarczone
          bezpośrednio przez niego lub pobrane automatycznie od podmiotu
          zbierającego te dane w imieniu użytkownika
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          Jeśli wyraził/a Pan/Pani zgodę na komunikację marketingową,
          wykorzystywane są Pana/Pani dane w postaci adresu poczty
          elektronicznej.
        </CustomText>
      </Row>
      <Row className="mt-4">
        <CustomText color={Colors.balck} size={20} bold>
          Cele przetwarzania danych oraz podstawa prawna przetwarzania
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • Przetwarzanie Pana/Pani danych osobowych jest niezbędne do
          przedstawienia propozycji działań profilaktyki zdrowotnej takich jak
          wykonanie badań medycznych, konsultacji z lekarzem, bądź zażywania
          suplementów diety. Podstawa prawna: art. 6 ust. 1 lit. a oraz art. 6
          ust 1 lit. b RODO.
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • Pana/Pani dane mogą być także przetwarzane na potrzeby prowadzenia
          ksiąg rachunkowych oraz rozliczeń podatkowych. Podstawa prawna: art. 6
          ust. 1 lit. c RODO w zw. z przepisami ustawy z dnia 29 września 1994
          r. o rachunkowości, ustawy z dnia 11 marca 2004 r. o podatku od
          towarów i usług oraz ustawy z dnia 26 lipca 1991r. o podatku
          dochodowym od osób fizycznych.
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • Dane mogą być również przetwarzane w celu obrony praw i dochodzenia
          roszczeń przez administratora danych w związku z prowadzoną przez
          niego działalnością. Podstawa prawna: art. 6 ust. 1 lit. b oraz f
          RODO.
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • Jeśli wyraził/a Pan/Pani zgodę na komunikację marketingową,
          Pana/Pani dane mogą być wykorzystywane dla celów marketingowych w
          odniesieniu do produktów i usług oferowanych przez administratora.
          Podstawą prawną jest Pana/pani zgoda, zgodnie z art. 6 ust.1 lit. a
          RODO.
        </CustomText>
      </Row>
      <Row className="mt-4">
        <CustomText color={Colors.balck} size={20} bold>
          Okres przechowywania danych
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          Dane osobowe mogą być usunięte w każdym momencie bezpośrednio przez
          użytkownika przy czym jest to równoznaczne z usunięciem konta w
          serwisie i zakończeniu korzystania z serwisu.
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          Dane związane ze stanem zdrowia Użytkownika mogą zostać usunięte w
          każdym momencie, ale ich usunięcie skutkuje jakości ograniczeniem
          zakresu świadczonych usług.
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          Gromadzone dane usuwane są niezwłocznie jeżeli Użytkownik zrezygnuje z
          korzystania z serwisu lub jeżeli Użytkownik nie korzysta z serwisu
          przez okres dłuższy niż 36 miesięcy.
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          Jeżeli wyraził/a Pan/Pani zgodę na komunikację w celach
          marketingowych, dane będą przetwarzane do momentu wycofania przez
          Pana/Panią zgody na przetwarzanie danych osobowych w tych celach.
        </CustomText>
      </Row>
      <Row className="mt-4">
        <CustomText color={Colors.balck} size={20} bold>
          Odbiorcy danych
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          Pana/Pani dane mogą być przekazywane podmiotom przetwarzającym dane
          osobowe na zlecenia administratora m.in. dostawcom usług IT oraz
          podmiotom przetwarzającym.
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          Ponadto, jeśli wyraził/a Pan/Pani zgodę na komunikację w celach
          marketingowych, Pana/Pani dane mogą być przekazywane podmiotom
          przetwarzającym dane osobowe na zlecenia administratora m.in.
          dostawcom usług IT czy agencjom marketingowym, a także podmiotom
          przetwarzającym.
        </CustomText>
      </Row>
      <Row className="mt-4">
        <CustomText color={Colors.balck} size={20} bold>
          Przekazywanie danych poza EOG
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          Pana/Pani dane osobowe mogą być przekazywane do odbiorców znajdujących
          się w państwach poza Europejskim Obszarem Gospodarczym. W takim
          przypadku przekazanie danych odbywać się będzie w oparciu o stosowną
          umowę pomiędzy administratorem danych a odbiorcą, która zawierać
          będzie standardowe klauzule ochrony danych przyjęte przez Komisję
          Europejską.
        </CustomText>
      </Row>
      <Row className="mt-4">
        <CustomText color={Colors.balck} size={20} bold>
          Prawa osoby, której dane dotyczą
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          Przysługuje Panu/Pani prawo:
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • <u>Dostępu do Pana/Pani danych osobowych</u> - uzyskania od
          administratora potwierdzenia, czy przetwarzane są Pana/Pani dane
          osobowe, a jeżeli ma to miejscu, uzyskania dostępu do nich oraz
          przekazania Panu/Pani informacji w zakresie wskazanym w art. 15 RODO.
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • <u>Sprostowania Pana/Pani danych osobowych</u> - żądania od
          administratora niezwłocznego sprostowania danych osobowych, które są
          nieprawidłowe, uzupełnienia niekompletnych danych osobowych.
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • <u>Usunięcia Pana/Pani danych osobowych</u> - żądania od
          administratora niezwłocznego usunięcia danych osobowych, jeżeli
          spełniona została jedna z przesłanek określonych w art. 17 RODO, m.in.
          dane osobowe nie są już niezbędne do celów, w których zostały zebrane.
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • <u>Ograniczenia</u> przetwarzania Pana/Pani danych osobowych w
          przypadkach wskazanych w art. 18 RODO, m.in. kwestionowania
          prawidłowości danych osobowych.
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • <u>Przenoszenia danych osobowych</u> - otrzymania od administratora
          Pana/Pani danych osobowych, w ustrukturyzowanym, powszechnie używanym
          formacie nadającym się do odczytu maszynowego, jeżeli Pana/Pani dane
          są przetwarzane na podstawie zgody oraz przetwarzanie odbywa się w
          sposób zautomatyzowany. Może Pan/Pani przesłać te dane innemu
          administratorowi danych lub żądać, by dane osobowe zostały przesłane
          przez administratora bezpośrednio innemu administratorowi, o ile jest
          to technicznie możliwe.
        </CustomText>
        <CustomText
          style={{ marginLeft: 20, marginTop: 10 }}
          color={Colors.balck}
          size={16}
        >
          • <u>Wniesienia</u> sprzeciwu wobec przetwarzania danych osobowych w
          przypadkach określonych w art. 21 RODO.
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          Przysługuje Panu/Pani również prawo wniesienia skargi do organu
          nadzorczego zajmującego się ochroną danych osobowych.
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          W celu skorzystania z powyższych praw należy skontaktować się z
          administratorem danych. Dane kontaktowe wskazane są wyżej.
        </CustomText>
      </Row>
      <Row className="mt-4">
        <CustomText color={Colors.balck} size={20} bold>
          Informacja o dobrowolności podania danych osobowych
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          Podanie danych osobowych jest warunkiem niezbędnym do poprawnego
          funkcjonowania serwisu i brak zgody na ich przetwarzanie jest
          równoznaczny z rezygnacją z korzystania z serwisu.
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          Podanie danych jest również niezbędne do wystawienia rachunku lub
          faktury.
        </CustomText>
      </Row>
      <Row className="mt-4">
        <CustomText color={Colors.balck} size={20} bold>
          Informacje o zautomatyzowanym podejmowaniu decyzji
        </CustomText>
        <CustomText style={{ marginTop: 10 }} color={Colors.balck} size={16}>
          Pana/Pani dane osobowe będą automatycznie analizowane w celu
          przedstawieniu propozycji działań profilaktyki medycznej. Wszelkie
          dalsze operacje wynikające z przedstawionych propozycji będą wykonane
          jedynie za zgodą użytkownika.
        </CustomText>
      </Row>
    </Row>
  );
}

export default PrivacyPolicyPl;
