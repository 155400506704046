import React from "react";

import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import CustomText from "../../components/CustomText";
import Colors from "../../constants/Colors";

import { IoLogoInstagram } from "react-icons/io";
import { IoLogoLinkedin } from "react-icons/io";

function Contact() {
  console.log("Render Contact");
  return (
    <Row className="mt-5 mb-5 text-center align-items-center justify-content-center">
      <Image
        style={{ height: 200, width: 230 }}
        src={process.env.PUBLIC_URL + "/logo192.png"}
      />
      <Row className="mt-3 justify-content-center">
        <CustomText color={Colors.primary} size={32} bold>
          Bartosz Nawrot
        </CustomText>
        <CustomText color={Colors.secondary} size={28}>
          contact@craftise.dev
        </CustomText>
      </Row>
      <Row className="mt-3 justify-content-center">
        <Col xs="auto">
          <Link
            to="https://www.instagram.com/barwalll/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IoLogoInstagram
              style={{ height: 50, width: 50 }}
              color={Colors.primary}
            />
          </Link>
        </Col>
        <Col xs="auto">
          <Link
            to="https://www.linkedin.com/in/bartosz-nawrot-aab890127/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IoLogoLinkedin
              style={{ height: 50, width: 50 }}
              color={Colors.primary}
            />
          </Link>
        </Col>
      </Row>
    </Row>
  );
}

export default Contact;
